import React, { ChangeEvent, useState, useRef } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TabProps } from './types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectAllDocuments } from '../../../../slices/documents/documentsSlice';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import AIIcon from '../../../../../static/assets/svg/ai-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../../../client/NWClient';
import { toast } from 'react-toastify';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { selectUser } from '../../../../slices/user/userSlice';
import { selectAllDocumentContracts } from '../../../../slices/document-contracts/documentContractsSlice';
import AISummaryDialog from '../../../../common/ai-summary-dialog/AISummaryDialog';
import PlanUpgradingMessage from '../../../../common/plan-upgrading-message/PlanUpgradingMessage';

const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
const checkedIcon = <CheckBoxIcon fontSize='medium' />;

export const DocumentSummaryTab = ({ currentTab, summary }: TabProps) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const contractGenerated = params.get('contractGenerated');
  const purposes = [
    {
      title: t('purpose.verification'),
      value: 'verification',
    },
    {
      title: t('purpose.analysis'),
      value: 'analysis',
    },
    {
      title: t('purpose.recordKeeping'),
      value: 'record keeping',
    },
  ];
  const token = localStorage.getItem('access_token');
  const company = useAppSelector(selectAllCompanies)[0];
  const { documentId } = useParams();
  const currentUser = useAppSelector(selectUser);
  const document = useAppSelector(selectAllDocuments).find((el) => el.id === Number(documentId));
  const documentContract = useAppSelector(selectAllDocumentContracts).find(
    (el) => el.id === Number(documentId)
  );
  const location = useLocation();
  const path = location.pathname;
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [aiDocumentDialogVisible, setAiDocumentDialogVisible] = useState(false);
  const [documentType, setDocumentType] = useState('contract');
  const [purpose, setPurpose] = useState(purposes);
  const [resultFormat, setResultFormat] = useState('table');
  const [detailLevel, setDetailLevel] = useState('high level');
  const [simplifyJargon, setSimplifyJargon] = useState(true);
  const [contextualInformation, setContextualInformation] = useState('');
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
  const divElement = useRef(null);

  const handleAnalyze = () => {
    setAnalysisLoading(true);
    NWClient.post(
      token,
      'ai-assist',
      {
        [contractGenerated ? 'document_contract_id' : 'document_upload_id']: Number(documentId),
        document_type: documentType,
        result_format: resultFormat,
        detail_level: detailLevel,
        purpose: JSON.stringify(purpose.map((el) => el.value)),
        simplify_jargon: simplifyJargon,
        contextual_information: contextualInformation,
        user: currentUser.id,
      },
      true
    )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (
          error.response?.data?.errors?.length > 0 &&
          error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
        ) {
          setPlanUpgradingMessage(true);
        } else {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        }
      });
  };

  const copyToClipboard = async () => {
    try {
      const html = divElement.current;

      const success = await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([html.innerHTML], {
            type: 'text/html',
          }),
        }),
      ]);
      toast.success(t('messages.textCopied'), {
        theme: 'colored',
      });
      return success;
    } catch (e) {
      toast.error(t('messages.errorOccurred'), {
        theme: 'colored',
      });
    }
  };

  return (
    <TabPanel value={currentTab} index={0} prefix={'vertical'}>
      <>
        <div className='p-4'>
          <div className='d-flex justify-content-between align-items-start'>
            <h2 className='mb-4 pe-2'>{t('documentTabs.summary')}</h2>
            {summary && (
              <div>
                <Tooltip
                  className='flex-shrink-0'
                  title={t('messages.AISummaryMessage')}
                  placement='top'
                  arrow
                >
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      setAiDocumentDialogVisible(true);
                    }}
                  >
                    <AIIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip className='flex-shrink-0' title={t('buttons.copy')} placement='top' arrow>
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      copyToClipboard();
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div ref={divElement} className='d-md-flex d-lg-flex w-100'>
            <div className='document-data-container flex-shrink-0'>
              <div className='data-row d-flex'>
                <div className='label'>{t('labels.fileName')}:</div>
                <div className='value-item'>
                  {contractGenerated ? documentContract?.name : document?.name}
                </div>
              </div>
              <div className='data-row d-flex'>
                <div className='label'>{t('labels.profile')}:</div>
                <div className='value-item text-capitalize'>
                  {contractGenerated ? documentContract?.category : document?.category}
                </div>
              </div>
              <div className='data-row d-flex'>
                <div className='label'>{t('labels.connected')}:</div>
                <div className='value-item'>{company?.name}</div>
              </div>
            </div>
            <div className='ps-4 flex-grow-1 summary-block'>
              {summary ? (
                parse(summary)
              ) : (
                <Button
                  type='button'
                  role='button'
                  variant='contained'
                  size='medium'
                  onClick={() => {
                    setAiDocumentDialogVisible(true);
                  }}
                >
                  {t('messages.AISummaryMessage')}
                </Button>
              )}
            </div>
          </div>
        </div>
        {aiDocumentDialogVisible && (
          <AISummaryDialog
            open={aiDocumentDialogVisible}
            documentContractIdCondition={Boolean(contractGenerated)}
            title={contractGenerated ? documentContract.name : document.name}
            handleClose={() => setAiDocumentDialogVisible(false)}
            handleAnalyzeSuccess={() => window.location.reload()}
            documentId={Number(documentId)}
            setAnalysisLoading={() => setAnalysisLoading(true)}
          />
        )}
        {pendingStatus ? <ProgressOverlay /> : ''}
        {analysisLoading ? (
          <div
            className='position-fixed w-100 vh-100 d-flex align-items-center justify-content-center'
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 3000, left: 0, top: 0 }}
          >
            <PacmanLoader loading={analysisLoading} color='#6414DB' />
          </div>
        ) : null}
        {planUpgradingMessage && (
          <PlanUpgradingMessage
            open={planUpgradingMessage}
            text={t('messages.monthLimitReached')}
            handleClose={() => setPlanUpgradingMessage(false)}
          />
        )}
      </>
    </TabPanel>
  );
};
